<template>
  <div>
    <div
      style="height: 100vh"
      class="
        d-flex
        justify-content-center
        align-items-center
        shadow-lg
        rounded-lg
      "
    >
      <div style="width: 100%">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="">
              <p class="animated bounce infinite text-2xl text-center">Loading ...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase";
let db = firebase.firestore();
import autophp from '@/plugins/autophp_kasir.js'
let sdb = new autophp()
export default {
  data() {
    return {
      vdata: {
        email: "",
        password: "",
      },
      cordova: false,
      error: null,
    };
  },
  methods: {
    login() {
      let that = this;
      if (typeof cordova == "object") {
        document.addEventListener("deviceready", function () {
          that.googleCordova();
        });
      } else {
        that.googleAuth();
      }
    },
    loginAuth() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.vdata.email, this.vdata.password)
        .then((data) => {
          let user = {
            uid: data.user.uid,
            displayName: data.user.displayName,
            email: data.user.email,
            emailVerified: data.user.emailVerified,
          };
          this.$store.commit("changeUsers", user);
          localStorage.setItem("users", JSON.stringify(user));
          this.$router.push("/driver");
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
    googleAuth() {
      let that=this;
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((data) => {
          let user = {
            uid: data.user.uid,
            displayName: data.user.displayName,
            email: data.user.email,
            emailVerified: data.user.emailVerified,
            gambar: data.user.photoURL,
            level:'user',
            auth:'google'
          };
          firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
              localStorage.setItem("auth._token.local", sdb.scramble("bearer " + idToken));
              localStorage.setItem("auth.local", sdb.scramble("bearer " + idToken));
            })
            .catch(function (error) {});
          this.$store.commit("changeUsers", user);
          // console.log('user',user)
          localStorage.setItem("users", JSON.stringify(user));
          sdb
            .collection("tbuser")
            .doc()
            .select(`select * from tbuser where uid='${data.user.uid}'`)
            .then((res) => {
              if (res.length > 0) {
                user.id = res[0].id;
                sdb
                  .collection("tbuser")
                  .doc()
                  .set(user)
                  .then((res) => {
                      that.$router.push('/')
                      sdb.loadingOff()
                    // console.log(res)
                    // console.log('update user')
                  });
              } else {
                sdb
                  .collection("tbuser")
                  .doc()
                  .set(user)
                  .then((res) => {
                      that.$router.push('/')
                      sdb.loadingOff()
                    // console.log('insert user')
                  });
              }
            });
        })
        .catch((err) => {
          alert("Oops. " + err.message);
            that.$router.push('/')
            sdb.loadingOff()
        });
    },
    googleCordova() {
      let that = this;
      window.plugins.googleplus.login(
        {
          webClientId:
            "891526924127-rb71763psofrs76tbnit9qn6t8j7ev32.apps.googleusercontent.com",
          offline: true,
        },
        function (obj) {
          firebase
            .auth()
            .signInWithCredential(
              firebase.auth.GoogleAuthProvider.credential(obj.idToken)
            )
            .then((data) => {
              let user = {
                uid: data.user.uid,
                displayName: data.user.displayName,
                email: data.user.email,
                emailVerified: data.user.emailVerified,
                gambar: data.user.photoURL,
                level:'user',
                auth:'google'
              };
              firebase
                .auth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then(function (idToken) {
                  localStorage.setItem(
                    "auth._token.local",
                    sdb.scramble("bearer " + idToken)
                  );
                  localStorage.setItem("auth.local", sdb.scramble("bearer " + idToken))
                })
                .catch(function (error) {});
              this.$store.commit("changeUsers", user);
              console.log("user", user);
              localStorage.setItem("users", JSON.stringify(user));
              sdb
                .collection("tbuser")
                .doc()
                .select(`select * from tbuser where uid='${data.user.uid}'`)
                .then((res) => {
                  if (res.length > 0) {
                    user.id = res[0].id;
                    sdb
                      .collection("tbuser")
                      .doc()
                      .set(user)
                      .then((res) => {
                      that.$router.push('/')
                        sdb.loadingOff()
                        // console.log(res)
                        // console.log('update user')
                      });
                  } else {
                    sdb
                      .collection("tbuser")
                      .doc()
                      .set(user)
                      .then((res) => {
                      that.$router.push('/')
                        sdb.loadingOff()
                        // console.log('insert user')
                      });
                  }
                });
            });
        },
        function (msg) {
          alert("error: " + msg);
            that.$router.push('/')
            sdb.loadingOff()
        }
      );
    },
  },
  mounted() {
    // this.$store.dispatch("ceklogin", { true: "/driver", false: true });
    let that = this;
    document.addEventListener("deviceready", function () {
      that.cordova = true;
    });
    setTimeout(() => {
        this.login()
    }, 2000);
  },
};
</script>